.File {
    padding: 16px;
    .main {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .File-item {
        margin: 0 8px 8px 8px;
        height: 48px;
        border-radius: 8px;
        margin-bottom: 8px;
        border: 2px solid #fbd3ed;
        font-size: 140%;
        font-weight: 600;
        align-items: center;
        color: #4f4f4f;
        cursor: pointer;
        &:hover{
            background: #e9e9e9;
        }
    }
}