.SubmissionPaper {
    .changePaperResult {
        background-color: #ff8b8b;        
    }
    .paper-item{
        border-radius: 5px;
        &:hover{
            background-color: #989898;
        }
    }
}