.Navigation {

  display: flex;
  align-items: center;
  margin-bottom: 4px;
  background-color: #ffffff;
  width: 100%;

  &.fixed {
    position: fixed;
    top: 4px;
    z-index: 1;
    width: 100%;

    @media screen and (min-width: 1100px) {
      max-width: 1200px;
      min-width: 1000px;
    }

    border-radius: 5px;
    border-top: 2px solid #ffc197;
    border-bottom: 2px solid #ffc197;
  }


  .i18n-area {
    .i18n-select-ui {
      display: none;

      @media screen and (min-width: 1100px) {
        display: flex;
      }
    }

    .i18n-select-h5 {
      display: flex;

      @media screen and (min-width: 1100px) {
        display: none;
      }
    }
  }

  .menu {
    display: flex;
    flex: 1;

    .ant-menu-root {
      width: 300px;

      @media screen and (min-width: 1100px) {
        width: 800px;
      }
    }

    .menu-mobile {
      display: flex;

      @media screen and (min-width: 1100px) {
        display: none;
      }
    }

    .menu-web {
      display: none;

      @media screen and (min-width: 1100px) {
        display: flex;
      }
    }
  }

  .ant-dropdown-menu {
    max-height: 200px;
    overflow: auto;
  }
}