.Dashboard {
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .site-layout {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

}

.ant-spin-nested-loading {
  display: flex;
  flex-direction: column;
  flex: 1;

  .ant-spin-container {
    display: flex;
    flex-direction: column;
    flex: 1;

    .ant-form {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

  }
}

.modal-theme {
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-modal {
    top: 0;
    max-height: 90vh;
    overflow: auto;
  }
}

.ck-editor__editable {
  min-height: 300px;
  max-height: 300px;
}

.ck-editor__editable_inline p {
  margin: 0;
}

// .ck-editor {
//   display: flex;
//   flex-direction: column;
//   flex: 1;

//   .ck-editor__main {
//     display: flex;
//     flex-direction: column;
//     flex: 1;

//     .ck-content {
//       display: flex;
//       flex-direction: column;
//       flex: 1;
//     }
//   }

// }

// .ck-editor__editable_inline:not(.ck-comment__input *) {
//   height: 300px;
//   overflow-y: auto;
// }

::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  //background-color: #8c8c8c;
  background-color: rgba(0, 0, 0, 0.25);
}

::-webkit-scrollbar-track {
  background-color: #737373;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
  border: 0;
}