.Sponser {
  display: flex;
  background-color: #ffc197;
  padding: 8px 0;
  margin: 4px 0;
  justify-content: center;
  align-items: center;

  .link-card {
    cursor: pointer;
    border-radius: 5px;
    height: 48px;
    margin: 0 8px;

    img {
      height: 100%;
    }
  }
}