.Announcement {
    padding: 16px;
    .main {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .Announcement-item {
        background: #f9f2ee;
        margin: 0 8px 8px 8px;
        height: 48px;
        border-radius: 8px;
        margin-bottom: 8px;
        border-bottom: 2px solid #ffc197;
        font-size: 140%;
        font-weight: 600;
        align-items: center;
        color: #4f4f4f;
        cursor: pointer;
        &:hover{
            background: #f3e3d9;
        }

        .Top{
            background: #ffc197;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
    }
}