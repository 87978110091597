.Introdution {
    display: flex;
    flex-direction: column;
    flex: 1;

    .upsert-page {
        display: flex;
        flex-direction: column;
        flex: 1;

        &.hide {
            display: none;
            width: 100%;
        }
    }
}