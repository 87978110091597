.CallForPapers {
    .types {
        display: flex;
        flex-wrap: wrap;

        .ant-badge {
            cursor: pointer;
        }
    }
}

.ant-modal-body {
    .types {
        display: flex;
        flex-wrap: wrap;

        .type-item.move{
            cursor: all-scroll;
        }
        .ant-badge {
            cursor: pointer;
        }
    }
}