.HomePage {
  display: flex;
  position: fixed;
  overflow: auto;
  align-items: center;
  flex-direction: column;
  background-color: #fdf2ea;
  width: 100%;
  height: 100vh;
  overflow: auto;
  font-family: Arial, Helvetica, sans-serif;

  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;

    @media screen and (min-width: 1100px) {
      min-width: 1000px;
      max-width: 1200px;
    }


    .banner {
      display: flex;
      width: 100%;
      background: #ffc197;
      margin-bottom: 4px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      flex: 1;

      .last-update-at {
        color: #c0bfbf;
        font-style: italic;
        font-size: 0.8rem;
      }

      .ct {
        display: flex;
        flex: 1;
        height: 100%;
        background: #ffffff;
        padding: 12px;
        width: 100vw;
        @media screen and (min-width: 1100px) {
          width: auto;
        }
        overflow: auto;
      }

      .schedule-time {
        background: #ffffff;
        padding: 16px;
        border-radius: 4px;
        margin: 4px 0;
        font-weight: 600;
      }

      .image.image_resized{
        img{
          width: 100%;
          height: auto;
        }
      }

    }

    .sponser {
      display: flex;
      background-color: #ffc197;
      height: 80px;
      margin: 4px 0;
    }

    .footer {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffc197;
      min-height: 40px;
    }
  }
}